var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-input-group',{staticClass:"mb-2"},[_c('b-input-group-prepend',[_vm._t("label",[_c('b-form-input',{staticClass:"form-control",attrs:{"id":_vm.labelId,"type":_vm.labelType,"placeholder":_vm.labelPlaceholder,"disabled":_vm.disabled},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.onEnter($event)}},model:{value:(_vm.inputLabel),callback:function ($$v) {_vm.inputLabel=$$v},expression:"inputLabel"}})],null,{
          id: _vm.labelId,
          value: _vm.inputLabel,
          setValue: function (value) { return (_vm.inputLabel = value); },
          type: _vm.labelType,
          placeholder: _vm.labelPlaceholder,
          classNames: 'form-control',
          disabled: _vm.disabled,
          onEnter: _vm.onEnter,
        })],2),_c('b-form-input',{staticClass:"form-control",attrs:{"id":_vm.valueId,"type":_vm.valueType,"placeholder":_vm.valuePlaceholder,"disabled":_vm.disabled},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.onEnter($event)}},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary","disabled":!_vm.isValid || _vm.disabled},on:{"click":function($event){return _vm.addCouple()}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1)],1),(_vm.isArray)?_c('b-list-group',_vm._l((_vm.value),function(item,index){return _c('b-list-group-item',{key:index,staticClass:"d-flex justify-content-between list-group-item",attrs:{"button":"","disabled":_vm.disabled},on:{"click":function($event){return _vm.removeCouple(index)}}},[_c('div',[_c('span',{staticClass:"text-muted font-weight-bold"},[_vm._v(_vm._s(_vm.getLabel(item)))]),_vm._v(" "+_vm._s(item[_vm.valueAttribute]))]),_c('feather-icon',{staticClass:"align-self-center",attrs:{"icon":"XIcon"}})],1)}),1):_c('b-list-group',_vm._l((_vm.value),function(item,value,index){return _c('div',{key:index},[(item !== null && item !== '')?_c('b-list-group-item',{staticClass:"list-group-item",attrs:{"button":"","disabled":_vm.disabled},on:{"click":function($event){return _vm.removeCouple(value)}}},[_c('feather-icon',{staticClass:"float-right",attrs:{"icon":"XIcon"}}),_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.localeText(value))+" ")]),_c('hr'),_vm._v(" "+_vm._s(item)+" ")],1):_vm._e()],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }