<template>
  <div>
    <b-input-group class="mb-2">
      <b-input-group-prepend>
        <slot
          name="label"
          v-bind="{
            id: labelId,
            value: inputLabel,
            setValue: (value) => (inputLabel = value),
            type: labelType,
            placeholder: labelPlaceholder,
            classNames: 'form-control',
            disabled: disabled,
            onEnter: onEnter,
          }"
        >
          <b-form-input
            :id="labelId"
            v-model="inputLabel"
            :type="labelType"
            :placeholder="labelPlaceholder"
            class="form-control"
            :disabled="disabled"
            @keydown.enter.prevent="onEnter"
          />
        </slot>
      </b-input-group-prepend>
      <b-form-input
        :id="valueId"
        v-model="inputValue"
        :type="valueType"
        :placeholder="valuePlaceholder"
        class="form-control"
        :disabled="disabled"
        @keydown.enter.prevent="onEnter"
      />
      <b-input-group-append>
        <b-button variant="primary" :disabled="!isValid || disabled" @click="addCouple()">
          <feather-icon icon="PlusIcon" />
        </b-button>
      </b-input-group-append>
    </b-input-group>
    <!-- For links -->
    <b-list-group v-if="isArray">
      <b-list-group-item
        v-for="(item, index) in value"
        :key="index"
        button
        class="d-flex justify-content-between list-group-item"
        :disabled="disabled"
        @click="removeCouple(index)"
      >
        <div><span class="text-muted font-weight-bold">{{ getLabel(item) }}</span> {{ item[valueAttribute] }}</div>
        <feather-icon icon="XIcon" class="align-self-center" />
      </b-list-group-item>
    </b-list-group>
    <!--  <p v-else>
      Actualment no tens cap link guardat
    </p> -->
    <!-- For locales -->
    <b-list-group v-else>
      <div v-for="(item, value, index) in value" :key="index">
        <b-list-group-item
          v-if="item !== null && item !== ''"
          button
          class="list-group-item"
          :disabled="disabled"
          @click="removeCouple(value)"
        >
          <feather-icon icon="XIcon" class="float-right" />
          <div class="font-weight-bold">
            {{ localeText(value) }}
          </div>
          <hr>
          {{ item }}
        </b-list-group-item>
      </div>
    </b-list-group>
  </div>
</template>

<script>
import { validatorUrlValidator } from '@/@core/utils/validations/validators';
import ActiveLinksLabels from '@/@core/constants/ActiveLinksLabels';

export default {
  name: 'FormCouples',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: [Array, Object],
      required: true,
    },
    labelId: {
      type: String,
      default: null,
    },
    labelType: {
      type: String,
      default: 'text',
      validator(type) {
        return ['text', 'email', 'tel', 'url', 'number'].includes(type);
      },
    },
    labelPlaceholder: {
      type: String,
      default: null,
    },
    labelAttribute: {
      type: String,
      default: 'label',
    },

    valueId: {
      type: String,
      default: null,
    },
    valueType: {
      type: String,
      default: 'text',
      validator(type) {
        return ['text', 'email', 'tel', 'url', 'number'].includes(type);
      },
    },
    valuePlaceholder: {
      type: String,
      default: null,
    },
    valueAttribute: {
      type: String,
      default: 'value',
    },

    disabled: Boolean,
    state: {
      type: Boolean,
      default: null,
    },
    areLinks: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputLabel: '',
      inputValue: '',
    };
  },
  computed: {
    websiteLabel() {
      return ActiveLinksLabels.map((label) => ({
        text: this.$t(`links.label.${label}`),
        value: label,
      }))[0];
    },
    isValid() {
      if (this.inputValue.length > 0 && this.inputValue.length < 255) {
        return true;
      }
      return (
        this.inputLabel.trim()
        && this.inputValue.trim()
        && this.inputLabel.length < 255
        && this.inputValue.length < 255
        && validatorUrlValidator(this.inputValue)
      );
    },
    isArray() {
      return Array.isArray(this.value);
    },
  },
  methods: {
    getLabel(item) {
      return this.areLinks ? item[this.labelAttribute] || this.websiteLabel?.text : item[this.labelAttribute];
    },
    onEnter() {
      if (this.isValid) {
        this.addCouple();
      }
    },
    addCouple() {
      if (this.isArray) {
        this.$emit('input', [
          { [this.labelAttribute]: this.inputLabel, [this.valueAttribute]: this.inputValue },
          ...this.value,
        ]);
      }
      this.value[this.inputLabel] = this.inputValue;
      this.inputValue = '';
    },
    removeCouple(index) {
      if (this.isArray) {
        this.$emit('input', [...this.value.slice(0, index), ...this.value.slice(index + 1)]);
      }
      /* this.$emit('input', this.value[index] = ''); */
      this.value[index] = '';
    },
    localeText(value) {
      switch (value) {
        case 'ca':
          return 'Català';
        case 'es':
          return 'Español';
        case 'en':
          return 'English';
        default:
          // should never happen.
          return null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.list-group .list-group-item {
  word-break: break-word;
}
</style>
